import React, { useState, useEffect, memo, useRef } from 'react'
import 'assets/scss/Web/Sell-website/Header/header.css'
import signature from 'assets/images/Sell-Website/logo3.svg'
import { NavLink } from 'react-router-dom'
import Zalo from 'assets/svg/zalo-svgrepo-com.svg'
import Phone from 'assets/svg/phone-call-svgrepo-com.svg'
const Index = ({ handleCallToServer }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    }
    const dpkCursorRef = useRef(null);
    const dpkCursorMouse = useRef({ x: -100, y: -100 });
    const dpkCursorPos = useRef({ x: 0, y: 0 });
    const speedOption = 0.25

    useEffect(() => {
        const updatePosition = () => {
            dpkCursorPos.current = {
                x: dpkCursorPos.current.x + (dpkCursorMouse.current.x - dpkCursorPos.current.x) * speedOption,
                y: dpkCursorPos.current.y + (dpkCursorMouse.current.y - dpkCursorPos.current.y) * speedOption
            }
            if (dpkCursorRef.current) { // Kiểm tra xem dpkCursorRef.current có tồn tại không
                dpkCursorRef.current.style.transform = `translate3d(calc(${dpkCursorPos.current.x}px - 50%), calc(${dpkCursorPos.current.y}px - 50%), 0)`;
            }
        };

        const handleMouseMove = (e) => {
            dpkCursorMouse.current = { x: e.clientX, y: e.clientY };
        };
        const handleCursorChange = (e) => {
            const tgt = e.target;
            const computed = window.getComputedStyle(tgt)["cursor"]
            if (computed === "pointer") {
                dpkCursorRef.current.classList.add("dpkCursor-hover");
            } else {
                dpkCursorRef.current.classList.remove("dpkCursor-hover");
            }
        };
        const loop = () => {
            updatePosition();
            requestAnimationFrame(loop);
        };
        window.addEventListener('mouseover', handleCursorChange);
        window.addEventListener("mousemove", handleMouseMove);
        loop();

        return () => {
            window.removeEventListener('mouseover', handleCursorChange);
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, [speedOption]);

    const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
    const isAndroid = /Android/.test(navigator.userAgent);
    const zaloLink = isIOS ? 'zalo://qr/p/8smsrkibge4y' : isAndroid ? 'https://zaloapp.com/qr/p/8smsrkibge4y' : 'zalo://conversation?phone=0374216188';
    return (
        <>
            <div className={isIOS ? 'hotline-icon-one-ios' : isAndroid ? 'hotline-icon-one-android' : 'hotline-icon-one-pc'}>
                <a href={zaloLink} onClick={() => { handleCallToServer('zalo') }} target='blank'>
                    <img src={Zalo} className='hotline-icon-svg' alt="Logo" />
                </a>
            </div>
            <div className='hotline-icon-two'>
                <a href="tel:0374216188" onClick={() => { handleCallToServer('call') }}>
                    <img src={Phone} onClick={() => { }} className='hotline-icon-svg' alt="Logo" />
                </a>
            </div>
            <div ref={dpkCursorRef} className="dpkCursor " />
            <div className=''>
                <style>
                    {`
          @import url("https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap");

          * {
              margin: 0;
              padding: 0;
              box-sizing: border-box;
              font-family: "Play", sans-serif;
          }
          
          body {
              font-size: 16px;
              padding-top: 84px;
              margin: auto;
              color: black;
          }
          
          .play-regular {
              font-family: "Play", sans-serif;
              font-weight: 400;
              font-style: normal;
          }
          
          .play-bold {
              font-family: "Play", sans-serif;
              font-weight: 700;
              font-style: normal;
              letter-spacing: 1px;
          }
           
        `}
                </style>
                <div className='header-sell-website'>
                    <nav className="navbar-sell-website play-bold">
                        <i className="hamburger-icon hamburger-btn fa-solid fa-bars" style={{ fontSize: 20 }} onClick={toggleMenu}></i>
                        <NavLink to='/' className="logo">
                            <img src={signature} alt="logo" />
                        </NavLink>
                        <NavLink to='/' className="logo-res">
                            <img src={signature} alt="logo" />
                        </NavLink>
                        <ul className={isMenuOpen ? "links show-menu" : "links"}>
                            <span className="close-btn material-symbols-rounded" onClick={toggleMenu}>x</span>

                            <li ><a href='#trangchu' onClick={toggleMenu}>Trang chủ</a></li>
                            <li ><a href='#dichvu' onClick={toggleMenu}>Dịch vụ</a></li>
                            <li ><a href='#banggia' onClick={toggleMenu}>Bảng giá</a></li>
                            <li ><a href='#danhgia' onClick={toggleMenu}>Đánh giá</a></li>
                            <li ><a href='#chatluong' onClick={toggleMenu}>Chất lượng</a></li>
                        </ul>
                        <a href="tel:0374216188" onClick={() => { handleCallToServer( 'call') }} className="login-btn" style={{ textDecoration: 'none', color: 'white' }}>Liên hệ</a>
                    </nav>

                </div>
            </div>
        </>
    );
}

export default memo(Index);
